import { useEffect, useState } from 'react'


export const fetchData = ( controllerFunction, params ) => {
    const [ data, setData ] = useState( [] )

    useEffect( () => {

        const fetchAPIData = async () => {
            const response = await controllerFunction( params )
            // Can include error handling here
            setData( response )
        }

        fetchAPIData()

    }, [ ...Object.values( params || {} ) ] || [ params ] )

    return data
}

export const useScreen = ( breakpoint=992 ) => {
    const [ mobile, setMobile ] = useState( typeof window !== 'undefined' ? window.innerWidth <= breakpoint : false )

    useEffect( () => {
        const handleResize = () => {
            setMobile( window.innerWidth <= breakpoint )
        }
        window.addEventListener( 'resize', handleResize )
        return () =>  window.removeEventListener( 'resize', handleResize )

    }, [] )

    return mobile


}

export const useData = ( controllerFunction, params, init=[] ) => {
    const [ data, setData ] = useState( init )

    useEffect( () => {

        const fetchAPIData = async () => {
            const response = await controllerFunction( params )
            // Can include error handling here
            setData( response )
        }

        fetchAPIData()

    }, [ ...Object.values( params || {} ) ] || [ params ] )

    return data
}

export const useDataOrNull = ( controllerFunction, params ) => {
    const [ data, setData ] = useState( undefined )

    useEffect( () => {

        const fetchAPIData = async () => {
            const response = await controllerFunction( params )
            // Can include error handling here
            setData( response )
        }

        fetchAPIData()

    }, [ ...Object.values( params || {} ) ] || [ params ] )

    return data
}



export const useLocalData = ( controllerFunction, params, key ) => {
    const [ data, setData ] = useState( [] )

    useEffect( () => {

        const fetchAPIData = async () => {
            const sessionData = localStorage.getItem( key ) && JSON.parse( localStorage.getItem( key ) )

            if( sessionData ) {
                const { age } = sessionData
                if( !age || ( Date.now() - age ) > 3600 )
                    localStorage.removeItem( key ) 
            }
            
            if( !sessionData ||  Object.keys( sessionData ).length === 0 || ( sessionData.header && sessionData.header.images.length === 0 ) ) {
                const response = await controllerFunction( params )
                if( response ){
                    response.age = Date.now()
                    localStorage.setItem( key, JSON.stringify( response ) )
                    // Can include error handling here
                }
                setData( response )
                
            }else{
                // console.log( sessionData.header.images )

                setData( sessionData )
            }
           
        }

        fetchAPIData()

    }, [ ...Object.values( params || {} ) ] || [ params ] )

    return data
}
