import React from 'react'


export default React.forwardRef( ( { className, children, show, handleClose }, ref ) => {
    const zIndex = 100

    return (
        <div ref={ref} className={`modal${className ? " " + className : ""}`} style={!show ? {} : { opacity: 1, zIndex }}>
            <div className="close-modal-button" onClick={handleClose}><span className="icon"><i className='fas fa-times'/></span></div>
            {children}
        </div>
    )


} )