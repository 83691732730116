/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const React = require( 'react' )
require( './src/assets/scss/main.scss' )
require( 'aos/dist/aos.css' )
const Layout = require( './src/layouts/index' ).default
const Footer = require( './src/components/Footer' ).default
const Context = require( './src/lib/context' )

// Wraps every page in a component
exports.wrapRootElement = ( { element } ) => {
    return <Context.BannerContextWrapper>{element}</Context.BannerContextWrapper>
}


// Wraps every page in a component
exports.wrapPageElement = ( { element } ) => {
    return <Layout>{element}</Layout>
}


// exports.shouldUpdateScroll = ( { routerProps: { location } } ) => {

//     if ( location.hash === '' ) {
//       window.scrollTo( 0, 0 )
//     }
  
//     return false
// }