import React, { createContext, useEffect, useState } from 'react'
import { fetchAllBanners } from '../lib/utils'

const BannerContext = createContext( {} )

export const BannerProvider = BannerContext.Provider

export const BannerContextWrapper = ( { children } ) => {
    const [ banners, setBanners ] = useState( {} )

    useEffect( () => {

        const getBanners = async () => {
            const bannerData = await fetchAllBanners()
            // console.log( bannerData ) 
            setBanners( bannerData )
                
        }

        Object.keys( banners ).length === 0 && getBanners()

    }, [] )

    return (
        <BannerProvider value={banners}>
            {children}
        </BannerProvider>
    )
}

export default BannerContext

