import React from "react"


import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'

export default ( { label, image } ) => {

    return (
            <div className="image-button">
                <span className="image fit image-button">
                    <img className='image-button-img' src={image || pic01} alt="" />
                    <span className="image-button-label">{label}</span>

                </span> 
            </div>
    )
}