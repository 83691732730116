import { useContext } from 'react'
import { banners } from './const'
import { getStaticPageData } from './API'

export const fetchAllBanners = async () => {
    const bannerMap = {}

    for await ( const bannerName of banners ) {
        const bannerData = localStorage.getItem( bannerName ) && JSON.parse( localStorage.getItem( bannerName ) )
        const CACHE_TTL = 1000 * 60 * 15
        if( !bannerData ||  Object.keys( bannerData ).length === 0 || Date.now() - bannerData.age > CACHE_TTL || ( bannerData.header && bannerData.header.images.length === 0 ) ) {
            const response = await getStaticPageData( bannerName )
            if( response ){
                response.age = Date.now()
                localStorage.setItem( bannerName, JSON.stringify( response ) )
                // Can include error handling here
                bannerMap[bannerName] = response
            }
        }else{
            bannerMap[bannerName] = bannerData
        }
            
    }

    return bannerMap
}


export const fetchRSS = ( url ) => fetch( url )
.then( response => response.text() )
.then( str => typeof window !== 'undefined' ? new window.DOMParser().parseFromString( str, "text/xml" ) : undefined )
.then( data => data )


export const changeFormValue = ( e, handler ) => handler( e.target.value ) 


export const slug = str => encodeURIComponent( str.replace( /\s+/g, '-' ).toLowerCase() )