import React from 'react'
import MainModalButtons from './MainModalButtons'
import Link from './Link'

const Footer = ( { location } ) => 
            <section id="footer">
               
               

                <div data-aos='fade-up' className="grid-wrapper">
                    <div className="col-3 footer-text">
                        <h2>Church Life</h2>
                        <ul className="links">
                            <li><a href='https://www.youtube.com/channel/UCo1-ajfKW62orxAK4TPkQ3Q/live' className='footer-link'>Watch Live Service</a></li>
                            <li><Link to="/church-life/#life-groups" className='footer-link'>Life Groups</Link></li>
                            <li><Link to="/missions" className='footer-link'>Ministries</Link></li>
                            <li><Link to="/testimonies" className='footer-link'>Testimonies</Link></li>
                            <li><Link to="/whats-on/#newsletter" className='footer-link'>Newsletter</Link></li>
                            <li><a href='#' className='footer-link'>Safeguarding Policy</a></li>
                            
                        </ul>
                    </div>
                    <div className="col-3 footer-text">
                        <h2>Links</h2>
                        <ul className="links">
                            <li><a href='https://www.bmsworldmission.org' className='footer-link'>Baptist Mission Society</a></li>
                            <li><a href='https://greenwich.foodbank.org.uk/' className='footer-link'>Greenwich Food Bank</a></li>
                            {/* <li><a href='https://gwns.org.uk/' className='footer-link'>Greenwich Winter Night Shelter</a></li> */}
                            <li><a href='https://www.privacypolicygenerator.info/live.php?token=oLhMGO9Zl1lgW5BgzSsb9g8jIYDZciIf' className='footer-link'>Privacy Policy</a></li>
                        </ul>
                    </div>

                    <div className="col-3 footer-text">
                        <h2> Service Times </h2>
                        <span className='text'>
                            Sunday Service: 10:30am - 12:00pm<br/>
                            Friday Evening Service: 7:00pm - 8:30pm<br/>
                            Prayer Meetings: Wednesday 6:00am & 7:00pm<br/>
                            Life Groups: Monday 7:00pm, Thursday 1:00pm & 8:00pm, Saturday 07:00am
                        </span>
                        
                         
                    </div>

                    <div className="col-3 footer-text">
                        <h2>Contact Us</h2>
                        <span className='text'>
                            Blackheath & Charlton Baptist Church<br/>
                            Marlborough Lane<br/>
                            London, SE7 7DF<br/>
                            Tel: 020 8856 8654<br/>
                        </span>
                    </div>
                    <div className="col-12 social-footer">
                        <ul className="icons">
                            <li><a target="_blank" href="https://twitter.com/bcb_church" className="icon alt"><i className='fab fa-twitter'></i></a></li>
                            <li><a target="_blank" href="https://www.facebook.com/Blackheath-Charlton-Baptist-Church-109100667686843" className="icon alt"><i className='fab fa-facebook'></i></a></li>
                            <li><a target="_blank" href="https://www.instagram.com/bcb__church/" className="icon alt"><i className='fab fa-instagram'></i></a></li>
                            <li><a target="_blank" href="mailto:admin@bcbchurch.co.uk" className="icon alt"><i className='fas fa-envelope'></i></a></li>
                        </ul>
                    </div>

                </div><br/>
                <ul className="copyright">
                    <li>&copy; Blackheath and Charlton Baptist Church</li>
                </ul>
                {/* <MainModalButtons /> */}
                

            </section>


export default Footer
