

export const banners = [
    'about-us-banners',
    'homepage-banners',
    'whats-on-banners',
    'serving-banners',
    'church-life-banners',
    'media-banners',
    'job-banners'
]